<template>
	<div class="admin-container">
		<div class="admin-contentBox">
			<div class="admin-title">广西天灿会计服务有限公司</div>
			<div class="admin-loginContainer">
				<div class="login-desk">
					<div class="desk-content">
						<div style="fontsize: 30px" class="deskItem">
							用服务解决客户需求
						</div>
						<div style="fontsize: 22px" class="deskItem">
							真诚沟通、热情、耐心、敬业
						</div>
						<div style="fontsize: 22px" class="deskItem">用心服务</div>
					</div>
				</div>
				<div class="login-container">
					<img src="../assets/codeSan.png" alt="" class="codesan" />
					<div class="saomaBtn-container">
						<div class="saomabtn" @click="islogin = 3">扫码登录</div>
					</div>
					<div class="loginSelect">
						<span :class="islogin == 1 ? 'is-select' : ' '" @click="islogin = 1">密码登录</span>
						<span :class="islogin == 2 ? 'is-select' : ' '" @click="islogin = 2">短信登录</span>
					</div>
					<div class="login-content" v-if="islogin == 1">
						<el-input v-model="LoginForm.account" placeholder="账号" @keyup.enter.native="login"></el-input>
						<el-input v-model="LoginForm.password" placeholder="密码" type="password"
							@keyup.enter.native="login"></el-input>
						<!-- <div class="verification_code-container">
              <el-input
                v-model="LoginForm.check_code"
                placeholder="请输入验证码"
              ></el-input>
              <div class="code">8897</div>
            </div> -->
						<div class="remPassword-container">
							<el-checkbox v-model="checked">记住密码</el-checkbox>
							<div>忘记密码？</div>
						</div>
						<div class="loginbuttonContainer">
							<div class="loginBtn" @click="login">登录</div>
							<div class="loginBtn-desk">
								建议使用火狐、谷歌浏览器、分辨率为1024*768以上
							</div>
						</div>
					</div>
					<div class="duanxin-content login-content" v-if="islogin == 2">
						<el-input v-model="LoginForm.account" placeholder="账号"></el-input>
						<div class="verification_code-container">
							<el-input v-model="LoginForm.check_code" placeholder="请输入验证码"></el-input>
							<div class="code">{{ LoginForm.check_code }}</div>
						</div>
						<div class="remPassword-container">
							<!-- <el-checkbox v-model="checked">记住密码</el-checkbox> -->
							<div>忘记密码？</div>
						</div>
						<div class="loginbuttonContainer">
							<div class="loginBtn">登录</div>
							<div class="loginBtn-desk">
								建议使用火狐、谷歌浏览器、分辨率为1024*768以上
							</div>
						</div>
					</div>
					<div class="code-content" v-if="islogin == 3">
						<img src="../assets/code.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="beian">
			<a href="https://beian.miit.gov.cn/">桂ICP备17003812号-3</a>
		</div>
	</div>
</template>

<script>
	import Vue from "vue";
	import Vuex from "vuex";

	Vue.use(Vuex);
	export default {
		data() {
			return {
				// useraccount: '',
				// userpassword: '',
				// verification_cod: '
				islogin: 1,
				LoginForm: {
					account: "",
					password: "",
					// check_code: ''
				},
				code: "",
				checked: "",
			};
		},
		mounted() {
			// this.getcheck_code()
		},
		methods: {
			// async getcheck_code() {
			//   const res = await this.$http.get('http://tc.qianji.org.cn/admin/Login/verify')
			//   console.log(res);
			//   if (res.status !== 200)
			//     return this.$message.error('获取验证码失败！')
			//   this.code = res.data
			// }

			async login() {
				const res = await this.$http.post("Login/login",this.LoginForm);
				console.log(res);
				if (res.data.code !== 200) return this.$message.error(res.data.msg);
				this.$message.success(res.data.msg);
				this.$store.commit("getadminlist", res.data.admin);
				//保存token
				window.sessionStorage.setItem("token", res.data.admin.token);
				// window.sessionStorage.setItem('adminlist', res.data.admin)
				// console.log(this.$store.state.adminlist);
				this.$router.push("./index");
			},
		},
	};
</script>
<style lang="scss">
	.beian {
		position: fixed;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);

		a {
			font-size: 20px;
			color: #ffffff;
			text-decoration: none;
		}
	}

	.code-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 50px;

		img {
			width: 150px;
			height: 150px;
		}
	}

	.admin-container {
		box-sizing: border-box;
		height: 100vh;
		background: url("../assets/indeximg/adminbg.png") no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;

		.admin-contentBox {
			margin: 0 auto;
			width: 1000px;

			.admin-title {
				text-align: left;
				font-weight: bold;
				font-size: 50px;
				color: #ffffff;
				margin-bottom: 30px;
			}

			.admin-loginContainer {
				width: 100%;
				height: 600px;
				box-sizing: border-box;
				background-color: #ffffff;
				border-radius: 20px;
				box-shadow: 0 0 0 1px hsla(240, 0%, 100%, 0.3) inset,
					0 0.5em 1em rgba(0, 0, 0, 0.6);
				text-shadow: 0 1px 1px hsla(240, 0%, 100%, 0.5);
				background-color: rgba(100, 100, 100, 0.2);
				display: flex;
				padding: 30px;
				align-items: center;

				.login-desk {
					flex: 1;
					color: #ffffff;
					text-align: left;

					.desk-content {
						padding-left: 20px;
						padding-top: 10px;
						padding-bottom: 10px;
						border-left: 2px solid #ffffff;

						.deskItem:first-child {
							letter-spacing: 2px;
							margin-bottom: 10px;
							font-size: 30px;
						}

						.deskItem:nth-child(2) {
							margin-bottom: 30px;
							letter-spacing: 2px;
							font-size: 22px;
						}

						.deskItem:last-child {
							color: #48bad1;
							font-size: 22px;
						}
					}
				}

				.login-container {
					box-sizing: border-box;
					width: 400px;
					border-radius: 20px;
					height: 100%;
					background-color: #ffffff;
					padding: 40px 50px;
					position: relative;
					display: flex;
					flex-direction: column;

					.codesan {
						top: 0;
						right: 0;
						position: absolute;
						z-index: 0;
					}

					.saomaBtn-container {
						display: flex;
						justify-content: flex-end;
						margin-bottom: 10px;

						.saomabtn {
							z-index: 1;
							color: $index-button-color;
							border: 1px solid $index-button-color;
							width: 80px;
							height: 30px;
							line-height: 30px;
							border-radius: 20px;
						}
					}

					.loginSelect {
						font-size: 26px;
						font-weight: bold;
						display: flex;
						justify-content: space-around;
						letter-spacing: 2px;

						.is-select {
							// margin-bottom: 30px;
							color: $index-button-color;
							border-bottom: 2px solid $index-button-color;
						}
					}

					.login-content {
						.el-input {
							margin-top: 30px;

							.el-input__inner {
								color: #666666;
								border-radius: 20px;
								height: 50px;
							}
						}

						.verification_code-container {
							display: flex;
							align-items: center;
							line-height: 50px;
							margin-top: 30px;

							.el-input {
								margin-top: 0;
							}

							.code {
								font-weight: bold;
								color: #ffffff;
								margin-left: 30px;
								padding: 0 30px;
								border-radius: 10px;
								background-color: $index-button-color;
							}
						}

						.remPassword-container {
							margin-top: 10px;
							display: flex;
							justify-content: space-between;
						}

						.loginbuttonContainer {
							margin-top: 30px;

							.loginBtn {
								border-radius: 20px;
								height: 50px;
								line-height: 50px;
								font-size: 20px;
								font-weight: bold;
								color: #ffffff;
								background-color: $index-button-color;
								cursor: pointer;
							}

							.loginBtn-desk {
								color: $index-hui-color;
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
</style>
